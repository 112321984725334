.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.main-chat-container {
  background-color: hsl(234, 8%, 26%);
  padding: 19px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.main-chat-chat {
  width: 100%;
  max-width: 800px; /* Adjust this value as needed */
}

.main-chat-reply {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.main-chat-name-text {
  font-weight: bold;
  color: white;
}

.main-chat-text {
  margin-left: 10px;
  color: white;
}

.main-chat-input {
  display:flex;
  align-items: center;
  justify-content: space-between; /* Add this line */
  margin-top: 9px;
}

.main-chat-form {
  display:flex;
  align-items: center;
  justify-content: space-between; /* Add this line */
  width: 100%;
}

.main-chat-input-text {
  width: 100%;
  margin-right:20px; /* Add this line */
}

.main-chat-input textarea {
  width: 100%;
  height: 30px;
  resize:auto;
  border-radius: 4px;
  padding: 8px;
  color: rgb(0, 0, 0);
  border: none;
  align-items: left;
}

.main-chat-input button {
  background-color: #e91e63;
  color: white;
  padding: 13px 12px;
  border: none;
  border-radius: 4px;
  font-size: 105%;
  height: 60px;
  width: 100px;
}

p {
  color: white;
  align-items: left;
}

